<template>
    <div class="card" v-if="!$route.query.id">
        <div class="card-header bg-white">
        <div class="row align-items-center">
            <div class="col-md-7">
            <h5 class="card-title font-weight-semibold">Daftar Racikan</h5>
            </div>
        </div>
        </div>
        <div class="card-header">
        <div class="row">
            <div class="col-md-7">
            <div class="form-row">
                <div class="col-md-3">
                <div class="result_tab">
                    <h4>Nama Dokter</h4>
                    <p>{{row.bu_full_name||"-"}}</p>
                </div>
                </div>
                <div class="col-md-3">
                <div class="result_tab">
                    <h4>Poli Layanan</h4>
                    <p>{{row.mpo_name||"-"}}</p>
                </div>
                </div>
            </div>
            </div>
            <div class="col-md-5">
            <div class="form-row justify-content-end">
                <div class="col-md-10">
                <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                    <input v-model="search" @input="doSearch($event)" class="form-control" placeholder="Ketik Nama Racikan" />
                    <div class="form-control-feedback">
                    <i class="icon-search4 text-indigo"></i>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover table-sm text-uppercase">
            <thead>
            <tr>
                <th>#</th>
                <th>Nama Racikan</th>
                <th>Satuan</th>
                <th>Komposisi</th>
                <th>Status</th>
                <th>Aksi</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(v,k) in listRacikan" :key="k">
                <td>{{k+1}}</td>
                <td>{{v.mrd_nama_racikan||"-"}}</td>
                <td>{{v.mrd_satuan||"-"}}</td>
                <td>{{getKomposisi(v.mrd_obat)}}</td>
                <td>
                    <span v-if="v.mrd_is_active == 'Y'" class="badge badge-success">Aktif</span>
                    <span v-else class="badge badge-danger">Inaktif</span>
                </td>
                <td>
                    <a href="javascript:;" @click="editRacikan(v.mrd_id)" class="btn alpha-success border-success text-success-800 btn-icon rounded-round btn-sm" data-popup="tooltip" title="" data-original-title="Edit"><i class="icon-pencil7"></i></a>
                    
                    <a href="javascript:;" @click="deleteRacikan(v.mrd_id)" class="btn alpha-danger border-danger text-danger-800 btn-icon rounded-round btn-sm" data-popup="tooltip" title="" data-original-title="Hapus"><i class="icon-bin"></i></a>
                </td>
            </tr>
            <tr v-if="!(listRacikan||[]).length">
                <td colspan="99" class="text-center">Tidak Ada Data</td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
    <div class="card" v-else>
        <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Tambah Racikan Dokter</h6>
        </div>
        
        <validation-observer
            v-slot="{ handleSubmit }" ref="VForm"
        >
        <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs, false, {'pageSlug': $route.params.pageSlug, 'pageId': $route.params.pageId}))">
        <div class="card-body p-3">
            <div class="row g-2">
            <div class="col-md-3">
                <div class="result_tab">
                <h4>Poli Layanan</h4>
                <p>{{row.mpo_name||"-"}}</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="result_tab">
                <h4>Nama Dokter</h4>
                <p>{{row.bu_full_name||"-"}}</p>
                </div>
            </div>
            <div class="w-100"></div>
            <div class="col-md-4">
                <div>
                <label for="cth. Racikan Amlodipin">Nama Racikan<strong class="text-danger">*</strong></label>
                <b-form-input type="text" v-model="row.mrd_nama_racikan" class="form-control" id="cth. Racikan Amlodipin"/>
                </div>
                <VValidate 
                    name="Nama Racikan" 
                    v-model="row.mrd_nama_racikan" 
                    :rules="toValidate(mrValidation.mrd_nama_racikan)"
                />
            </div>
            <div class="col-lg-9">
                <h6>Kebutuhan Obat untuk 1 Satuan Racikan</h6>
                <table class="table table-bordered table-striped table-sm">
                <thead>
                    <tr>
                    <th width="48">#</th>
                    <th width="33%">Obat</th>
                    <th width="33%">Dosis</th>
                    <th width="33%">Jumlah</th>
                    <th width="33%">Aksi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in row.mrd_obat" :key="k">
                    <td>{{k + 1}}</td>
                    <td>
                        <v-select placeholder="Pilih Obat" @input="selectObat($event,k)" v-model="row.mrd_obat[k]['value']" :options="getMasterObat(row.mrd_obat,k)" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        
                        <VValidate 
                            :name="'Obat #'+(k+1)" 
                            v-model="row.mrd_obat[k]['value']" 
                            :rules="{required : 1}"
                        />
                    </td>
                    <td>
                        <div class="input-group">
                        <input v-model="row.mrd_obat[k]['dosis']" type="text" class="form-control" placeholder="cth. 120">
                        <div class="input-group-append">
                        <input style="width: 90px;" v-model="row.mrd_obat[k]['satuan_dosis']" type="text" class="form-control" placeholder="cth. Kaplet">
                        </div>
                        </div>
                    </td>
                    <td>
                        <div class="input-group">
                        <b-form-input type="text" v-model="row.mrd_obat[k]['jumlah']" :formatter="$parent.number" class="form-control" placeholder="cth. 5"/>
                        <div class="input-group-append"><span class="input-group-text">{{row.mrd_obat[k]['satuan_jumlah']||"-"}}</span></div>
                        </div>
                        
                    </td>
                    <td>
                        <a href="javascript:;" @click="row.mrd_obat.splice(k,1)" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus Obat"><i class="icon-trash"></i></a>
                    </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                    <td colspan="9" class="text-center">
                        <a href="javascript:;" @click="addObat" class="btn btn-outline-info">
                        <i class="icon-plus2 align-middle mr-1"></i>
                        <span class="align-middle">Tambah Obat</span>
                        </a>
                    </td>
                    </tr>
                </tfoot>
                </table>
            </div>
            <div class="w-100"></div>
            <div class="col-md-4">
                <div>
                <label for="">Jumlah & Satuan<strong class="text-danger">*</strong></label>
                <div class="input-group">
                    <b-form-input type="text" :formatter="$parent.number" v-model="row.mrd_jumlah" class="form-control" id="cth. Racikan Amlodipin" placeholder="Jumlah Default Racikan"/>
                    <div class="input-group-append">
                    <v-select placeholder="Pilih Satuan" style="width: 160px;" v-model="row.mrd_satuan" :options="mTypeSatuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                </div>
                <VValidate 
                    name="Jumlah" 
                    v-model="row.mrd_jumlah" 
                    :rules="toValidate(mrValidation.mrd_jumlah)"
                />
                <VValidate 
                    name="Satuan" 
                    v-model="row.mrd_satuan" 
                    :rules="toValidate(mrValidation.mrd_satuan)"
                />
                </div>
            </div>
            
            <b-col lg="6">
            <b-form-group class="mt-3">
                <label>Status <span class="text-danger mr5">*</span></label>
                <b-form-radio-group
                :options="Config.mr.StatusOptions"
                v-model="row['mrd_is_active']"
                />
                
                <VValidate 
                    :name="'Status'" 
                    v-model="row['mrd_is_active']" 
                    :rules="{required: 1}"
                />
            </b-form-group>
            </b-col>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex">
            <button type="button" @click="$router.back()" class="btn btn-transparent ml-auto mr-2">Back</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
        </div>
        </b-form>
        </validation-observer>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mObat:Array,
    listRacikan: Array,
    mTypeSatuan: Array
  },
  data() {
    return {
        search : '',
    }
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    
    doSearch: _.debounce(function(e){
        let dataSearch = e.target.value
        Gen.apiRest("/do/"+this.$parent.modulePage,{data:{type:'search-racikan',search: dataSearch, id: this.row.bu_id}}, "POST").then(res=>{
            this.$parent.listRacikan = res.data.list
        })
        .catch(err=>{ 
            this.$parent.loadingOverlay = false 
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.title = err.response.data.title
                err.message = err.response.data.message
                err.messageError = err.message
            }
            this.$parent.doSetAlertForm(err)
        })        
    },1000),
    addObat(){
        this.row.mrd_obat.push({
            value : null,
            dosis : null,
            satuan_dosis : null,
            jumlah : null,
            satuan_jumlah : null,
        })
    },
    selectObat(e,k){
        let index = this.mObat.findIndex(x => x.value == e)
        if(index !== -1){
            let data = this.mObat[index]
            this.row.mrd_obat[k]['dosis'] = data.dosis
            this.row.mrd_obat[k]['satuan_dosis'] = data.satuan_dosis
            this.row.mrd_obat[k]['satuan_jumlah'] = data.satuan            
        }
    },
    editRacikan(id){
        this.$router.push({ name: 'Dokter', params: { pageSlug: 'racikan', pageId: this.$route.params.pageId}, query: {id: id} }).catch(()=>{})
    },
    getKomposisi(data){
        let text = ''
        let obat = []
        for (let i = 0; i < data.length; i ++) {
            let index = this.mObat.findIndex(x => x.value == data[i]['value'])        
            if(index !== -1){
                obat.push(this.mObat[index]['text'])
            }
        }
        text = obat.join(', ')
        return text
    },
    deleteRacikan(id){
        this.$swal({
        title: 'Hapus Data ' + 'Racikan' + '?' || 'Data' + '?',
        text: "Anda tidak akan dapat memulihkan data ini!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, Hapus!',
        cancelButtonText: 'Kembali',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$parent.loadingOverlay = true
          Gen.apiRest("/do/"+this.$parent.modulePage,{data:{type:'delete',id:id}}, "POST")
            .then((resp = {})=>{
              this.$parent.apiGet()
              //let obj = this.$route.meta.objName
              this.$swal({
                title: resp.data.message || 'Deleted Successfully!',
                icon: 'success',
              })
            })
            .catch(err=>{ 
              this.$parent.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response.data.title
                  err.message = err.response.data.message
                  err.messageError = err.message
              }
              this.$parent.doSetAlertForm(err)
            })        
        }
      })
    },
    getMasterObat(data,k){
        const sldMenu = _.map(_.filter(data||[], (v2, k2)=>{
            return k2!=k
        }), v2=>{
            return v2.value
        })
        return _.filter(this.mObat, v2=>{
            return sldMenu.indexOf(v2.value)<=-1
        })
    },
  },
}
</script>