<template>
  <div class="content-wrapper">
    <bo-page-title>
        <template slot="additionalCta">
            <b-button v-if="pageSlug=='racikan' && !$route.query.id" @click="addNewRacikan()" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
            <b><i class="icon-plus2"></i></b> Tambah Racikan Baru
            </b-button>

            <b-button v-if="pageSlug=='alkes' && !$route.query.id" @click="addNewPaketAlkes()" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
            <b><i class="icon-plus2"></i></b> Tambah Paket Alkes Baru
            </b-button>
        </template>
    </bo-page-title>

    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col md="8">
              <b-form-row>
                <b-col md="6" lg="4">
                  <b-form-group class="mb-0">
                    <v-select placeholder="Pilih Poli" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col md="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            
            <template
              #cell(mpo_name)="data"
            >
                {{data.item.mpo_name||"-"}}
            </template>
            <template
              #cell(action)="data"
            >
            <div class="list-icons">
                
                <router-link class="btn btn-icon rounded-round btn-sm alpha-purple border-purple text-purple-800" v-if="moduleRole('atur_preferensi_obat')"
                  :to="{name: $route.name, params: {pageId: data.item[idKey], pageSlug: 'preferensi'}}"
                  data-toggle="tooltip" data-placement="top" v-b-tooltip.hover.bottom title="Atur Preferensi Obat"><i
                    class="icon-paste"></i></router-link>
                
                <router-link class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-if="moduleRole('atur_racikan_dokter')"
                  :to="{name: $route.name, params: {pageId: data.item[idKey], pageSlug: 'racikan'}}"
                  data-toggle="tooltip" data-placement="top" v-b-tooltip.hover.bottom title="Atur Racikan Dokter"><i
                    class="icon-stack-text"></i></router-link>
                    
                <router-link class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800" v-if="moduleRole('atur_paket_alkes')"
                  :to="{name: $route.name, params: {pageId: data.item[idKey], pageSlug: 'alkes'}}"
                  data-toggle="tooltip" data-placement="top" v-b-tooltip.hover.bottom title="Atur Paket Alkes"><i
                    class="icon-stack-text"></i></router-link>

                <span v-if="!(moduleRole('atur_preferensi_obat') || moduleRole('atur_racikan_dokter') || moduleRole('atur_paket_alkes'))">-</span>
            </div>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else>
        <FormPreferensiObat v-if="pageSlug=='preferensi'" :row.sync="row" v-bind="passToSub" />

        <ListRacikan v-if="pageSlug=='racikan'" :row.sync="row" v-bind="passToSub" />
        <ListAlkes v-if="pageSlug=='alkes'" :row.sync="row" v-bind="passToSub" />
      </template>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import FormPreferensiObat from './FormPreferensiObat.vue'
import ListRacikan from './ListRacikan.vue'
import ListAlkes from './ListAlkes.vue'

const _ = global._

export default {
  extends: GlobalVue,
  components:{FormPreferensiObat,ListRacikan,ListAlkes},
  data() {
    return {
      idKey:'bu_id',
      statusKey:'bu_is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'bu_full_name',
          label: 'Nama Dokter',
        },
        {
          key: 'mpo_name',
          label: 'Poli',
        },
        {
          key: 'action',
          label: 'Aksi',
        },
      ],
      mPoli: [],
      mObat: [],
      mAlatKesehatan: [],
      listRacikan: [],
      mTypeSatuan: []
    }
  },
  computed:{
    passToSub(){
      return {
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        mObat: this.mObat,
        mAlatKesehatan: this.mAlatKesehatan,
        listRacikan: this.listRacikan,
        mTypeSatuan: this.mTypeSatuan,        
      }
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
    console.log(this.filter)
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    addNewRacikan(){
      this.$router.push({ name: 'Dokter', params: { pageSlug: 'racikan', pageId: this.$route.params.pageId}, query: {id: 'new'} }).catch(()=>{})
    },
    addNewPaketAlkes(){
      this.$router.push({ name: 'Dokter', params: { pageSlug: 'alkes', pageId: this.$route.params.pageId}, query: {id: 'new'} }).catch(()=>{})   
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>